import websocket from '@giantmachines/redux-websocket'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { apiMiddleware } from 'redux-api-middleware'
import createDebounce from 'redux-debounced'
import logger from 'redux-logger'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import authReducer from './api/redux/authReducer'
import { emlycreateFetchMyNewDatasetsRSAAReducer } from './api/redux/DatasettReducer'
import { EmlyNotificationReducer } from './api/redux/EmlyNotificationReducer'
import { emlyFrameWork } from './api/redux/FrameWorkReducer'
import * as notificationCenterReducers from './api/redux/NotificationCenter/reducers'
import notificationReducer from './api/redux/notificationReducer'
import * as apiReducers from './api/redux/reducers'
import { routKeysReducer } from './api/redux/routeKeysReducer'
import statusReducer from './api/redux/statusReducer'
import { userProfilePersisted, userProfilePersistedToken } from './api/redux/UserProfile'
import { userTheme } from './api/redux/UserTheme'
import customErrorMiddleware from './customErrorMiddleware'
const initialState = {}

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'authReducer',
    'auth',
    'apiError',
    'ws',
    'api',
    'myApps',
    'myProfile',
    'uberIsDown',
    'isBillingExpire',
    'theme',
    'checkUberDeployment',
    'myUserAccountInfo',
    'cancelUploadingDataSet',
    'cayleyPreviewerData',
    'cayleyExplorerData',
    'qsDataSetItemSchema',
    'appSettings',
    'datasetGroupDetail',
    'datasetDetailEmbed',
    'myDatasetsEmbed',
    'datasetDetail',
    'myDatasets',
    'myReccTemplates',
    'myModels',
    'myTemplates',
    'deploymentProfiles',
    'userMetaData',
    'buildApp',
    'appQuickSettings',
    'stopAppJob',
    'startAppJob',
    'appDetailQuickSetting',
    'appItreationDetail',
    'appDetail',
    'datoinServices',
    'itrSampleModel',
    'modelDetails',
    'datasetSchemas',
    'itrSampleData',
    'myHelpLinks',
    'userProjectPermissions',
    'emlycreateFetchMyNewDatasetsRSAAReducer',
    'fetchAllgroups',
    'getPushedProjects',
    'myProjects',
    'specificOrgPracticeFlows',
    'isSubscriptionCheck'
  ]
}

let middlewareState = []

if (window.NODE_ENV === 'DEV') {
  middlewareState = applyMiddleware(createDebounce(), thunk, apiMiddleware, websocket, logger, customErrorMiddleware)
} else {
  middlewareState = applyMiddleware(createDebounce(), thunk, apiMiddleware, websocket, customErrorMiddleware)
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const combined = combineReducers({
  ...apiReducers,
  ...notificationCenterReducers,
  auth: authReducer,
  notification: notificationReducer,
  EmlyNotificationReducer: EmlyNotificationReducer,
  bgStatus: statusReducer,
  emlycreateFetchMyNewDatasetsRSAAReducer: emlycreateFetchMyNewDatasetsRSAAReducer,
  emlyFrameWork: emlyFrameWork,
  routKeysReducer: routKeysReducer,
  userProfilePersisted: userProfilePersisted,
  userProfilePersistedToken: userProfilePersistedToken,
  theme: userTheme
})

const resetableRootReducer = (state, action) => {
  if (action.type === 'SIGNOUT_SUCCESS') return combined(undefined, action)
  else return combined(state, action)
}
const persisted = persistReducer(persistConfig, resetableRootReducer)
const store = createStore(persisted, initialState, composeEnhancers(middlewareState))

export default store
export const persistor = persistStore(store)
