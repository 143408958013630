import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { getCurrentContextuserIdDetails } from './AccountUtility'
import './admin.css'

//@Author Aadil Masavir
//Aadilmasavir@datoin.com
//datoin.com
//02 December 2022

const index = props => {
  const [userOrganizations, setUserOrganizations] = useState(null)

  useEffect(() => {
    if (props.myUserAccountInfo.isLoading === false) fetchUserOrganizations()
  }, [props.myUserAccountInfo.isLoading])

  const fetchUserOrganizations = () => {
    if (props.myUserAccountInfo.rolesUser) {
      setUserOrganizations(
        props.myUserAccountInfo.rolesUser.organizations ? props.myUserAccountInfo.rolesUser.organizations : []
      )
    }
  }

  const showModal = it => {
    props.showSwitch(it)
  }

  let current_logged_user = getCurrentContextuserIdDetails(props.myUserAccountInfo.current_logged_user)

  if (props.myUserAccountInfo.isLoading || userOrganizations === null)
    return (
      <div className="organizations">
        <div key={0} className="items checked">
          No organization found
        </div>
      </div>
    )
  else {
    return (
      <>
        <div className="admin dropDownContainer">
          <div className="mainItemView">
            {userOrganizations.length > 0 && (
              <div className="organizations">
                {(() => {
                  let organizations = []
                  userOrganizations
                    .sort(function (a, b) {
                      a = a.name.toLowerCase()
                      b = b.name.toLowerCase()
                      if (a < b) {
                        return -1
                      }
                      if (a > b) {
                        return 1
                      }
                      return 0
                    })
                    .map((it, i) => {
                      organizations.push(
                        current_logged_user.orgId === it.id ? (
                          <div key={i} className="items checked">
                            <span className="o-title">{it.title} </span>
                            <span className="o-tick">
                              <i class="fa fa-check" aria-hidden="true"></i>
                            </span>
                          </div>
                        ) : (
                          <div key={i} className="items" onClick={() => showModal(it)}>
                            <span className="o-title">{it.title} </span>
                          </div>
                        )
                      )
                    })
                  return organizations.map(item => item)
                })()}
              </div>
            )}
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  api: state.api,
  myUserAccountInfo: state.myUserAccountInfo
})

export default withRouter(connect(mapStateToProps)(index))
