import BatchAppIcon from '../../../images/Admin-Panel-Assets/BatchApp.png'
import DataIcon from '../../../images/Admin-Panel-Assets/DataIcon.png'
import DeploymentIcon from '../../../images/Admin-Panel-Assets/DeploymentIcon.png'
import HelpIcon from '../../../images/Admin-Panel-Assets/HelpIcon.png'
import NoDataYetIllustration from '../../../images/Admin-Panel-Assets/Illustrations/NoDataYetIllustration.png'
import LoadingSpinner from '../../../images/Admin-Panel-Assets/LoadingWithoutBG.gif'
import MicroServiceIcon from '../../../images/Admin-Panel-Assets/MicroService.png'
import ModelIcon from '../../../images/Admin-Panel-Assets/ModelIcon.png'
import NotificationsIcon from '../../../images/Admin-Panel-Assets/NotificationsIcon.png'
import quickHelpIcon from '../../../images/Admin-Panel-Assets/quickHelpIcon.png'
import SettingsIcon from '../../../images/Admin-Panel-Assets/SettingsIcon.png'
import StatusIcon from '../../../images/Admin-Panel-Assets/StatusIcon.png'
import logo from '../../../images/LOGO.png'

export const Logo = {
  datoin: logo
}
export const helpIcon = {
  icon: HelpIcon,
  icon2: quickHelpIcon
}
export const Icons = {
  settingsIcon: SettingsIcon,
  notificationIcon: NotificationsIcon,
  batchAppIcon: BatchAppIcon,
  microServiceIcon: MicroServiceIcon
}
export const ProjectPageIcons = {
  DataIcon: DataIcon,
  DeploymentIcon: DeploymentIcon,
  StatusIcon: StatusIcon,
  ModelIcon: ModelIcon
}
export const NoData = {
  image: NoDataYetIllustration
}
export const Loading = {
  image: LoadingSpinner
}
export default Logo
