import React, { Component } from 'react'
import Switch from 'react-switch'

class FlipSwitch extends Component {
  constructor(props) {
    super(props)
    this.state = { checked: this.props.graph ? false : true }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(checked) {
    this.setState({ checked: checked })
    this.props.handleHideErrorIter(this.props.graph ? checked : !checked)
  }
  render() {
    return (
      <label className="flp-sw">
        <span className="l">{this.props.label}</span>
        <Switch onChange={this.handleChange} checked={this.state.checked} />
      </label>
    )
  }
}
export default FlipSwitch
