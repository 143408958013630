import React from 'react'
import { connect } from 'react-redux'
import detectOutsideEvent from '../../components-examples/utils/detectOutsideEvent'
import { showNotification } from '../../redux/notificationAction'
import AppStatus from '../AppStatus'
import Tabs from '../Tabs'
import { Icons } from './imagePaths'
import Notifications from './Notifications'

const NotificationPanel = ({
  setNotificationPanel,
  dispatch,
  notificationpanel,
  setActive,
  active,
  setRemoveIds,
  removeIds,
  taskWindow,
  tasks,
  disabled,
  subscriptionUsage,
  checkoutBilling,
  myProfile
}) => {
  const impactRef = React.useRef()
  detectOutsideEvent(impactRef, () => setNotificationPanel(false))

  const notificationss = window.localStorage.getItem('notifications')

  const setNotifications = time => {
    setRemoveIds([...removeIds, ...[time]])
    const updatedNotifications = JSON.parse(notificationss).map(i => {
      if (time === i.time) {
        return { ...i, highlighted: false }
      } else {
        return i
      }
    })
    window.localStorage.setItem('notifications', JSON.stringify(updatedNotifications))
  }

  const tabData = [
    {
      name: 'Activities',
      id: 'Activities',
      onClickTab: value => {
        setActive(value)
      },
      component: (
        <div className="notification-activities customScrollDsgn">
          <AppStatus tasks={tasks} closeNotification={() => setNotificationPanel(false)} />
        </div>
      )
    },
    {
      name: 'Messages',
      id: 'Messages',
      number:
        Array.isArray(JSON.parse(notificationss)) &&
        JSON.parse(notificationss)
          .slice(0, 30)
          .filter(i => i.highlighted).length,
      onClickTab: value => {
        setActive(value)
      },
      component: <Notifications notificationss={notificationss} setNotifications={setNotifications} />
    }
  ]

  const isNotification =
    Array.isArray(JSON.parse(notificationss)) &&
    JSON.parse(notificationss)
      .slice(0, 30)
      .find(i => i.highlighted === true) !== undefined
      ? true
      : false

  const isRunning =
    taskWindow !== undefined &&
    taskWindow.length > 0 &&
    filterLatestObjects(taskWindow.filter(i => isJson(i).time !== undefined)).find(i => i.status === 'RUNNING')

  const getClassforBadge = () => {
    const coinsAvailable =
      subscriptionUsage && !subscriptionUsage.isLoading && subscriptionUsage.coinsAvailable
        ? subscriptionUsage.coinsAvailable
        : 'NA'
    let className = ''
    if (coinsAvailable <= 100) {
      className = 'em-flex em-credit-badge cbRed'
    } else if (coinsAvailable <= 250) {
      className = 'em-flex em-credit-badge cborange'
    } else if (coinsAvailable > 250) {
      className = 'em-flex em-credit-badge cbGreen'
    } else {
      className = 'em-flex em-credit-badge'
    }
    return className
  }

  return (
    <>
      {myProfile && myProfile.roles && myProfile.roles === 'owner' && (
        <div className={getClassforBadge()} onClick={() => checkoutBilling('/organizations/billings')}>
          <img className="em-coin-badge-icon" src={require('../../../images/coins.svg')}></img>
          <span className="em-coin-badge-span">
            {subscriptionUsage && !subscriptionUsage.isLoading && subscriptionUsage.coinsAvailable ? (
              Math.round(subscriptionUsage.coinsAvailable * 100) / 100
            ) : (
              <i className="fa fa-spinner em-coin-spinner-icon fa-pulse" />
            )}
          </span>
        </div>
      )}

      <button
        className="tp-notfiy"
        style={{ background: 'transparent', border: 0, padding: 0 }}
        onClick={() => {
          dispatch(showNotification(false))
          disabled === false && setNotificationPanel(!notificationpanel)
        }}
      >
        {isNotification && <div className="higlighted" />}
        {isRunning && (
          <img
            style={{ width: 30, height: 30, marginRight: -25 }}
            src={require('../../../images/Notification/Loading.gif')}
          ></img>
        )}
        <img src={Icons.notificationIcon}></img>
      </button>
      {notificationpanel && (
        <div className="notifications-top" ref={impactRef}>
          <h1>Notifications</h1>
          <Tabs active={active} tabData={tabData} tabWidth={100} tabText={true} />
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  taskWindow: state.emlyFrameWork.taskWindow
})

export default connect(mapStateToProps)(NotificationPanel)

const isJson = array => {
  try {
    return JSON.parse(array.replace(/'/g, '"'))
  } catch (e) {
    return array
  }
}

function filterLatestObjects(objects) {
  const idMap = new Map()

  for (const obj of objects) {
    if (!idMap.has(obj.id) || obj.time > idMap.get(obj.id).time) {
      idMap.set(obj.id, obj)
    }
  }

  return Array.from(idMap.values())
}
