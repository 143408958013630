export const EmlyNotificationReducer = (state = { notifications: [], setNotification: false }, action) => {
  switch (action.type) {
    case 'SHOW_NOTIFICATION':
      return {
        ...state,
        notifications: { ...state.notifications, ...action.payload }.notifications,
        showNotification: action.payload.notification
      }
    default:
      return state
  }
}

export const showNotification = text => {
  return {
    type: 'SHOW_NOTIFICATION',
    payload: text
  }
}
