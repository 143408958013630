export function userTheme(state = { action: null }, action) {
  switch (action.type) {
    case 'THEME1':
      return {
        action: { theme: action.payload }
      }
  }
  return state
}

export function setTheme(payload) {
  return {
    type: 'THEME1',
    payload: payload
  }
}
