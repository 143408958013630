import React from 'react'
import { NormalButton } from '../api/components-examples/ReusableCode/EmlyButton'
import './index.css'

const Error = () => {
  return (
    <div className="em-row keycloackerror">
      <div className="em-flex" style={{ flexWrap: 'nowrap', width: '60%' }}>
        <img src={require('../images/onBoard/launch.png')} style={{ width: 100 }} />

        <h1>Uh-oh! Authentication hiccup detected!</h1>
      </div>
      <h2>To resolve this issue</h2>
      <ol>
        <li>
          <span>Check Internet Connection:</span> Ensure your connection is stable.
        </li>
        <li>
          <span>Reload Page:</span> Refresh your browser.
        </li>
        <li>
          <span>Contact Support:</span> If problems persist, contact support@emlylabs.com
        </li>
      </ol>
      <h3>Thank you for your patience while we maneuver through this glitch!</h3>
      <div className="em-row err-reload">
        <NormalButton
          className="em-btn emly-btn-danger"
          onClick={() => window.location.reload(true)}
          title={'Reload'}
          style={{ width: '300px' }}
        >
          <i class="fa fa-refresh"></i> Reload
        </NormalButton>
      </div>
    </div>
  )
}

export default Error
