import React from 'react'
import './ResponsiveMessage.css'

const MessageBox = () => {
  return (
    <div className="message">
      <div className="message-content">
        Currently, our application is not optimized for mobile browsers. For the best user experience, please access our
        service from a desktop or bigger Screen devices.
      </div>
    </div>
  )
}

export default MessageBox
