import React from 'react'
import ProgressBar from '../Progress'
import { ErrorScreen } from './ErrorScreen'

class Overlay extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.props.overlay && (
          <div style={{ height: '100vh' }} className={'overlay model-space'}>
            <div className="transparent" />
            <div style={{ backgroundColor: '#fff', flex: 2 }}>
              <div className="modalConatiner-all-content">
                {this.props.error !== null ? (
                  <ErrorScreen
                    buttonText={'Retry'}
                    tipMessage={this.props.tipMessage}
                    error={this.props.error}
                    onResolve={this.props.onResolve}
                  />
                ) : (
                  <>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <h1 style={{ fontWeight: 'bold' }}>Create Sample Project</h1>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img
                          src={require('../../../images/onBoard/Loading Bar.gif')}
                          style={{ width: 200, height: 200 }}
                        />
                      </div>
                      <p style={{ color: 'grey', textAlign: 'center' }}>Your project is getting created, Please wait</p>
                    </div>
                    {this.props.status && (
                      <ProgressBar status={this.props.status} instanceState={this.props.instanceState} />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
}
export default Overlay
