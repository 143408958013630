import React from 'react'
import { NormalButton } from '../../api/components-examples/ReusableCode/EmlyButton'

export const BackNavigation = ({ backs }) => {
    return (
        <NormalButton
            onClick={() => backs()}
            style={{ margin: '12px 0', width: 'max-content', justifyContent: 'center' }}
            className="em-btn em-flex gback"
        >
            <svg viewBox="0 0 512 512">
                <g id="Icon">
                    <path
                        id="_136"
                        d="m268.8 338.5-82.5-82.5 82.5-82.5c9.8-9.8 9.8-25.6 0-35.4-9.8-9.8-25.6-9.8-35.4 0l-100.2 100.2c-9.8 9.8-9.8 25.6 0 35.4l100.2 100.2c9.8 9.8 25.6 9.8 35.4 0 9.7-9.8 9.7-25.6 0-35.4zm74.6 35.4-100.2-100.2c-9.8-9.8-9.8-25.6 0-35.4l100.2-100.2c9.8-9.8 25.6-9.8 35.4 0 9.8 9.8 9.8 25.6 0 35.4l-82.5 82.5 82.5 82.5c9.8 9.8 9.8 25.6 0 35.4-9.8 9.7-25.6 9.7-35.4 0z"
                    />
                </g>
            </svg>
            Go Back
        </NormalButton>
    )
}
