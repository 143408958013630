import React, { Component } from 'react'
import { connect } from 'react-redux'

class MainErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: null, errorInfo: null }
    this.goBack = this.goBack.bind(this)
  }
  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true, errorInfo: errorInfo })
  }

  goBack = () => {
    this.props.history.goBack()
    setTimeout(() => {
      this.reload()
    }, 2000)
  }

  reload = () => {
    window.location = '/'
  }

  render() {
    if (this.state.hasError || this.props.error) {
      return (
        <div style={{ display: 'flex', flex: 1 }}>
          <div className="emly-center">
            <div className="error-item">`${window.REACT_APP_ERROR_MESSAGE}`</div>
            {this.props.history && this.props.history.length > 1 ? (
              <div className="error-item">
                <div className="emly-btn emly-btn-danger" onClick={this.goBack}>
                  <i class="fa fa-refresh"></i> Reload
                </div>
              </div>
            ) : (
              <div className="error-item">
                <div className="emly-btn emly-btn-danger" onClick={this.reload}>
                  <i class="fa fa-refresh"></i> Refresh
                </div>
              </div>
            )}
          </div>
        </div>
      )
    }
    return this.props.children
  }
}
export default connect(state => state)(MainErrorBoundary)
