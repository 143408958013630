import React from 'react'
import './index.css'

const ProgressBar = ({ status, instance, instanceState }) => {
  const numBar =
    status === 'IN PROGRESS'
      ? [null]
      : status === 'COPYING_DATA'
      ? ['1']
      : status === 'CONFIGURING_APP'
      ? ['1', '2']
      : status === 'FINALIZING'
      ? ['1', '2', '3', '4']
      : null

  const instanceBar = instance === 1 ? ['1'] : instance === 2 ? ['1', '2'] : instance === 3 ? ['1', '2', '3'] : null
  const statuses = Array.from({ length: status === undefined ? 3 : 4 }, (_, i) => (i + 1).toString())
  const check = status === undefined ? instanceBar : numBar
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }} className="progressPage">
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {statuses.map(i => {
          return (
            <>
              <div className={check !== null && check.includes(i) ? 'color' : 'bar'} />
              <div className={'outer'}>
                <div className="inner">
                  <p className="number">{i}</p>
                </div>
              </div>
            </>
          )
        })}
      </div>
      <br />
      <div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p className="numbers">1. </p>
          <p style={{ fontWeight: 'bold', fontSize: 15, color: 'grey' }}>
            {' '}
            {status === undefined ? 'Creating your organization' : 'In Progress'}{' '}
            {check !== null && check.includes('1') && (
              <i style={{ color: 'green', fontSize: 17, marginLeft: 10 }} class="fa fa-check-circle "></i>
            )}
          </p>
          {instanceState === 'first' && <p className="spinner"></p>}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p className="numbers">2. </p>
          <p style={{ fontWeight: 'bold', fontSize: 15, color: 'grey' }}>
            {' '}
            {status === undefined ? 'Checking your service status' : 'Copying Data'}{' '}
            {check !== null && check.includes('2') && (
              <i style={{ color: 'green', fontSize: 17, marginLeft: 10 }} class="fa fa-check-circle "></i>
            )}
          </p>
          {instanceState === 'second' && <p className="spinner"></p>}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p className="numbers">3. </p>
          <p style={{ fontWeight: 'bold', fontSize: 15, color: 'grey' }}>
            {' '}
            {status === undefined ? 'Setting Up your instance' : 'Configuring App'}
            {check !== null && check.includes('3') && (
              <i style={{ color: 'green', fontSize: 17, marginLeft: 10 }} class="fa fa-check-circle "></i>
            )}{' '}
          </p>
          {instanceState === 'third' && <p className="spinner"></p>}
        </div>
        {instance === undefined && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p className="numbers">4. </p>
            <p style={{ fontWeight: 'bold', fontSize: 15, color: 'grey' }}>
              {' '}
              {status === undefined ? 'Setting Up your instance' : 'Finalizing'}
              {check !== null && check.includes('4') && (
                <i style={{ color: 'green', fontSize: 17, marginLeft: 10 }} class="fa fa-check-circle "></i>
              )}{' '}
            </p>
            {instanceState === 'fourth' && <p className="spinner"></p>}
          </div>
        )}
      </div>
    </div>
  )
}

export default ProgressBar
