import BuildLoading from './images/Admin-Panel-Assets/BuildLoading.gif'
import HelpIcon from './images/Admin-Panel-Assets/HelpIcon.png'
import Collapsible from './images/Admin-Panel-Assets/menu.png'
import NotificationsIcon from './images/Admin-Panel-Assets/NotificationsIcon.png'
import SettingsIcon from './images/Admin-Panel-Assets/SettingsIcon.png'
import UserWelcome from './images/Admin-Panel-Assets/UserWelcome.png'
import DsCDataset from './images/datasets/CreateDataSet.svg'
import DsCFolder from './images/datasets/CreateFolder.svg'
import DsCreateGroup from './images/datasets/CreateGroup.svg'
import DsDataset from './images/datasets/DsDataset.svg'
import DsDatasets from './images/datasets/DsDatasets.svg'
import DsFile from './images/datasets/DsFile.svg'
import DsFolder from './images/datasets/DsFolder.svg'
import DsFolderWhite from './images/datasets/DsFolderWhite.svg'
import DsGridLayout from './images/datasets/GridLayout.svg'
import DsGroup from './images/datasets/Group.svg'
import DsHelp from './images/datasets/Help.svg'
import DsListLayout from './images/datasets/listDataset.svg'
import DsUpload from './images/datasets/Upload.svg'
import apps from './images/EmlyIcons-SVG/Apps.png'
import dataLab from './images/EmlyIcons-SVG/DataLab.png'
import hub from './images/EmlyIcons-SVG/Hub.png'
import pipeline from './images/EmlyIcons-SVG/Pipeline.png'
import vizard from './images/EmlyIcons-SVG/Vizard.png'
import whitPipeline from './images/EmlyIcons-SVG/whitePipeline.svg'
import logo from './images/LOGO.png'

// setting name same as resource/responce name receiving from fetch

export const Logo = {
  datoin: logo,
  UserWelcome: UserWelcome,
  collapsibleIcon: Collapsible
}
export const helpIcon = {
  icon: HelpIcon
}

export const Icons = {
  settingsIcon: SettingsIcon,
  notificationIcon: NotificationsIcon,
  apps: apps,
  hub: hub,
  dataLab: dataLab,
  pipeline: pipeline,
  vizard: vizard,
  whitPipeline: whitPipeline
}

export const loader = {
  build: BuildLoading
}

export default Logo

export const DatasetIcons = {
  DsCDataset: DsCDataset,
  DsCFolder: DsCFolder,
  DsDataset: DsDataset,
  DsDatasets: DsDatasets,
  DsFile: DsFile,
  DsFolder: DsFolder,
  DsFolderWhite: DsFolderWhite,
  DsGridLayout: DsGridLayout,
  DsListLayout: DsListLayout,
  DsHelp: DsHelp,
  DsUpload: DsUpload,
  DsGroup: DsGroup,
  DsCreateGroup: DsCreateGroup
}
