export const routKeysReducer = (state = {}, action) => {
  switch (action.type) {
    case 'PROJECT_COMPONENT_NAV_LINK':
      return {
        ...state,
        projectComponentKey: action.payload
      }
    default:
      return state
  }
}

export function setProjectNavKey(payload) {
  return {
    type: 'PROJECT_COMPONENT_NAV_LINK',
    payload: payload
  }
}
