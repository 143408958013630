import React from 'react'
import { getCurrentTimeDate } from '../../../components/functions'

const Notifications = ({ notificationss, setNotifications }) => {
  return (
    <>
      {notificationss !== null ? (
        <div className="notification-messages customScrollDsgn">
          {Array.isArray(JSON.parse(notificationss)) ? (
            JSON.parse(notificationss)
              .slice(0, 30)
              .map(i => (
                <>
                  <div onClick={() => setNotifications(i.time)} className="notification-item-div">
                    <div>
                      {i.highlighted === true && <div className="higlighted" />}

                      <div style={{ backgroundColor: avatarColor(i.type) }} className="notification-item-avatar" />
                    </div>
                    <div className="notification-item">
                      <p style={{ color: i.highlighted ? '#000' : 'grey' }} className="item">
                        {i.message}
                      </p>
                      <p className="item-time">{getCurrentTimeDate(i.time)}</p>
                    </div>
                  </div>
                </>
              ))
          ) : (
            <div className="no-data">No notifications found.</div>
          )}
        </div>
      ) : (
        <div className="no-data">No notifications found.</div>
      )}
    </>
  )
}

export default Notifications

export const avatarColor = key => {
  switch (key) {
    case 'danger':
      return 'red'
    case 'success':
      return 'green'
    case 'error':
      return 'red'
    case 'info':
      return 'deepskyblue'
    case 'warning':
      return 'orange'
    default:
      return 'gray'
  }
}
