export const getCurrentTimeDate = timestamp => {
  // Assuming you have a timestamp in milliseconds (Unix timestamp)

  // Convert timestamp to a Date object
  const dateObject = new Date(timestamp)

  const time = dateObject.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
  const dayOfWeek = dateObject.toLocaleDateString('en-US', { weekday: 'long' })

  return `${dayOfWeek} ${time}`
}

export const isObjectEqual = objB => {
  const keysA = Object.keys({
    Title: 'Start Blank Project',
    flow_id: 'blank_project',
    learn: true,
    industry: [],
    process_flow: []
  })
  if (typeof objB !== 'object' || objB === null) {
    return false
  }
  console.log(
    keysA.every(key => objB.hasOwnProperty(key)),
    'check flow failures',
    objB
  )
  return keysA.every(key => objB.hasOwnProperty(key))
}

export const getCurrentTime = timestamp => {
  const date = new Date(timestamp)
  const seconds = date.getSeconds()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  return `${hours} : ${minutes} : ${seconds}`
}

export function timestampToYyyyMmDd(timestamp) {
  // Assuming you have a timestamp in milliseconds (Unix timestamp)

  // Convert timestamp to a Date object
  const dateObject = new Date(timestamp)

  // Get the date components (year, month, day)
  const year = dateObject.getFullYear()
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0') // Months are zero-based
  const day = dateObject.getDate().toString().padStart(2, '0')

  // Combine the components in 'yyy/mm/dd' format
  return `${year}-${month}-${day}`
}

export function capitalizeFirstLetter(str) {
  const value = str.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const randomColor = alphabet => {
  switch (alphabet && alphabet.toLowerCase()) {
    case 'a':
      return 'blue'
    case 'b':
      return 'green'
    case 'c':
      return 'pink'
    case 'd':
      return 'yellow'
    case 'e':
      return 'purple'
    case 'f':
      return 'orange'
    case 'g':
      return 'teal'
    case 'h':
      return 'red'
    case 'i':
      return 'cyan'
    case 'j':
      return 'lime'
    case 'k':
      return 'magenta'
    case 'l':
      return 'indigo'
    case 'm':
      return 'brown'
    case 'n':
      return 'navy'
    case 'o':
      return 'coral'
    case 'p':
      return 'silver'
    case 'q':
      return 'gold'
    case 'r':
      return 'crimson'
    case 's':
      return 'olive'
    case 't':
      return 'orchid'
    case 'u':
      return 'salmon'
    case 'v':
      return 'seagreen'
    case 'w':
      return 'sienna'
    case 'x':
      return 'tan'
    case 'y':
      return 'thistle'
    case 'z':
      return 'turquoise'
    default:
      return 'black' // Default color for unknown characters
  }
}

export const boxColor = key => {
  switch (key) {
    case 'Approved':
      return 'blue'
    case 'Not Started':
      return 'lightgrey'
    case 'Completed':
      return '#75AF00'
    case 'In Progress':
      return 'Orange'
    case 'Started':
      return 'grey'
    case 'Skip':
      return 'grey'
    default:
      return 'lightgrey' // Default color for unknown characters
  }
}
