export const isJson = settingObject => {
  try {
    return JSON.parse(settingObject)
  } catch (e) {
    return settingObject
  }
}

export const getCurrentContextuserIdDetails = current_logged_user => {
  const userId =
    window.localStorage.getItem('userID') !== undefined && window.localStorage.getItem('userID') !== null
      ? window.localStorage.getItem('userID')
      : current_logged_user
  const defaultProfileSetting = isJson(window.localStorage.defaultProfileSetting)
  if (defaultProfileSetting === undefined || defaultProfileSetting === null) {
    return 'NOCACHE'
  } else {
    const cacheObject = defaultProfileSetting[userId]
    if (cacheObject) {
      const activOrg = cacheObject['activeOrganization']
      const currentOrgDetails = cacheObject[activOrg]
      const currentUser = {
        currentUser: userId,
        orgId: activOrg,
        orgIdName: currentOrgDetails.orgIdName,
        spaceId: currentOrgDetails.spaceId,
        spaceIdName: currentOrgDetails.spaceIdName
      }
      return currentUser
    } else {
      return 'NEWUSER'
    }
  }
}

export const removeDeletedCredientals = userId => {
  let defaultProfileSetting = isJson(window.localStorage.defaultProfileSetting)
  if (defaultProfileSetting !== undefined && defaultProfileSetting !== null) {
    delete defaultProfileSetting[userId]
  }
  window.localStorage.setItem('defaultProfileSetting', JSON.stringify(defaultProfileSetting))
}

export const getListOfOrganizations = keycloakToken => {
  const fetchurl = `${window.REACT_APP_GATEWAY_API_URL}/profile/userRoles/user`
  return fetch(fetchurl, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${keycloakToken}`
    }
  })
    .then(response => {
      return response.json()
    })
    .then(response => {
      return response
    })
    .catch(err => {
      console.log('Error Occured while fetching the user organizations', err)
    })
}
