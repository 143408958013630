import { ADD_TOAST_NOTIFICATION, REMOVE_NOTIFICATION } from './actionTypes'

const initialState = {
  notificationList: [],
  saveNotifications: [],
  showNotification: false,
  currentNotification: null,
  activity: false
}

export default function (state = initialState, actions) {
  switch (actions.type) {
    case ADD_TOAST_NOTIFICATION: {
      const time = new Date().getTime()
      const currentNotification = actions.payload.persistent
        ? [{ time: time, highlighted: true, ...actions.payload }]
        : []
      const previousNotifications = window.localStorage.getItem('notifications')
      const checkNotifications =
        window.localStorage.getItem('notifications') === null ? [] : JSON.parse(previousNotifications)

      // Ensure checkNotifications is an array before spreading
      const allnotifications = [
        ...currentNotification,
        ...(Array.isArray(checkNotifications) ? checkNotifications : [])
      ].slice(0, 30)

      window.localStorage.setItem('notifications', JSON.stringify(allnotifications))
      return {
        ...state,
        notificationList: actions.payload,
        saveNotifications: [{ ...state.saveNotifications, ...actions.payload }],
        currentNotification: actions.payload
      }
    }
    case REMOVE_NOTIFICATION: {
      return {
        ...state,
        notificationList: [state.notificationList].filter(n => n.key !== actions.payload.key)
      }
    }
    case 'EMPTY_NOTIFICATION': {
      window.localStorage.setItem('notifications', null)
      return {
        ...state,
        notificationList: []
      }
    }
    case 'ACTIVITE_ACTIVITY': {
      return {
        ...state,
        activity: actions.payload
      }
    }
    case 'SET_NOTFICATION': {
      return {
        ...state,
        showNotification: actions.payload
      }
    }
    default:
      return state
  }
}
