import shortid from 'shortid'
import { ADD_TOAST_NOTIFICATION, REMOVE_NOTIFICATION } from './actionTypes'

export const addNotification = (message, type, persistence = true, delay = 3000, header = '') => {
  return {
    type: ADD_TOAST_NOTIFICATION,
    payload: {
      key: shortid.generate(),
      type,
      persistent: persistence,
      timerdelay: delay,
      message,
      header
    }
  }
}

export const removeNotification = notification => {
  return {
    type: REMOVE_NOTIFICATION,
    payload: notification
  }
}

export const showNotification = notification => {
  return {
    type: 'SET_NOTFICATION',
    payload: notification
  }
}

export const emptyNotification = notification => {
  return {
    type: 'EMPTY_NOTIFICATION',
    payload: notification
  }
}

export const activateActivity = activity => {
  return {
    type: 'ACTIVITE_ACTIVITY',
    payload: activity
  }
}
