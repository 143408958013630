import React from 'react'

export const SignUpLeft = props => {
  return (
    <div className="content-div signup">
      <div className="all-content">
        {props.invitationCode === null && (
          <div className="top-content em-row">
            <div className="h1-title">Experience Emly Labs with 21-Days Free Trial</div>
            <div className="h3-helper">No Credit Card Required</div>
          </div>
        )}
        <div className="ul-list em-row">
          <ul>
            <li>
              <img src={require('../../../images/SignupImages/IconSignuptick.svg')} className="icon-signup-tick" />
              Start Building within Minutes with No Special AI Expertise Required
            </li>
            <li>
              <img src={require('../../../images/SignupImages/IconSignuptick.svg')} className="icon-signup-tick" />
              Step-by-Step Guided Workflows for Industry Use-Cases
            </li>
            <li>
              <img src={require('../../../images/SignupImages/IconSignuptick.svg')} className="icon-signup-tick" />
              Native Collaboration and AI project Management
            </li>
            <li>
              <img src={require('../../../images/SignupImages/IconSignuptick.svg')} className="icon-signup-tick" />
              Instantly Gain Insights by Uploading Your Data with One-Click
            </li>
          </ul>
        </div>
        <div className="em-row icon-signup-logo-div">
          <img src={require('../../../images/onBoard/EwhiteLogo.png')} className="icon-signup-logo" />
        </div>
      </div>
    </div>
  )
}
