import actionTypes from '../actionTypes'

export function notificationCenter(
  state = {
    tasks: {}
  },
  action
) {
  switch (action.type) {
    case actionTypes.NOTIFICATION_CENTER_ADD_TASK_REQUEST:
      console.info('action----->', action)
      const newTask = action.payload.task
      console.info('task ----->', newTask)
      console.info('task.uuid ----->', newTask.uuid)
      let { tasks } = state
      tasks[newTask.uuid] = newTask
      let newState = { ...state, tasks }
      return newState
      break
  }
  return state
}
