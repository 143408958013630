import React from 'react'
import '../ReusableCode/emlyMain.css'

export const ErrorScreen = ({ error, onResolve, tipMessage = null, buttonText = 'Okay' }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        marginTop: 30
      }}
    >
      <img style={{ width: 200, height: 200 }} src={require('../../../images/SignupImages/error.png')} />
      <p style={{ color: 'red', fontSize: 16, fontWeight: 'bold', marginTop: 20 }}>
        {error ? error : 'We are unable to Process, Please try later'}
      </p>
      <p style={{ color: 'grey', fontSize: 16, fontWeight: 'bold', marginTop: 10 }}>{tipMessage}</p>
      <button style={{ width: 300 }} onClick={onResolve} className="emly-btn-primary action">
        {buttonText}
      </button>
    </div>
  )
}
