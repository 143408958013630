// In your React component
import { EventSourcePolyfill } from 'event-source-polyfill'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getCurrentContextuserIdDetails } from './api/components-examples/Admin/AccountUtility'
import { makeJsons } from './api/components-examples/utils/commonUtilityFunctions'
import { addTaskWindow } from './api/redux/FrameWorkReducer'

const SSE = ({ api, id, dispatch, history }) => {
  useEffect(() => {
    const user = getCurrentContextuserIdDetails(id)

    const url = `${window.REACT_APP_GATEWAY_API_V3_URL}/org/${user.orgId}/project/${user.spaceId}/notifications`

    const sse = new EventSourcePolyfill(url, {
      headers: {
        Authorization: `Bearer ${api.keycloakToken()}`,
        'Content-Type': 'text/event-stream',
        'user-agent': 'vscode-restclient',
        Connection: 'keep-alive'
      },
      heartbeatTimeout: 190000,
      withCredentials: true
    })

    sse.onmessage = e => {
      console.log(e, 'SSE message')
      const message = isJson(e.data)

      if (isEmptyObject(message)) {
        console.log('SSE Received', message)

        if (message.type === 'UBER_DEPLOYMENT') {
          if (message.status === 'DELETED') {
            navigateTo('/orgInstance')
          } else {
            return null
          }
        }
        if (message.type === 'DATASET') {
          // dispatch(addNotification(datasetSSEPlaceholder(message), 'normal', true))
          //dispatch(showNotification(true))
        } else {
          if (message.id !== '' && message.type === 'JOB') {
            dispatch(addTaskWindow({ ...makeJsons(message), ...{ time: new Date().getTime() } }))
          }
        }
      }
    }
    sse.onerror = event => {
      sse.close()
    }
    return () => {
      //   Step 3: Clean up the connection
    }
  }, [])

  const navigateTo = path => {
    history.push(path)
  }

  return <div>{/* Your component content */}</div>
}
const mapStateToProps = state => ({})

export default connect(mapStateToProps)(SSE)

const isJson = array => {
  try {
    return JSON.parse(array)
  } catch (e) {
    return array
  }
}

function isEmptyObject(obj) {
  return Object.entries(obj).length > 0
}
