import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import './index.css'

const index = props => {
  const prepareBreadCrumb = bArray => {
    let breadCrumb = []
    bArray &&
      bArray.map((it, index) => {
        breadCrumb.push(
          <span
            onClick={() => navigateTo(it.path, it.dispatch, it.func, it.pushState)}
            className={`cb-title ${it.active === true ? 'cb-active' : 'cb-inactive'}`}
          >
            {it.title}
          </span>
        )
        if (index !== bArray.length - 1) breadCrumb.push(<span className="cb-arrow"> {'>'} </span>)
      })
    return breadCrumb.map(it => it)
  }

  const navigateTo = (path, dispatch, func, pushState) => {
    if (dispatch !== null) {
      dispatch(dispatch)
    }

    if (func !== null) {
      func()
    }

    if (path !== null) {
      const { history } = props

      const cPath = { pathname: path, ...(pushState !== undefined && { pushState: pushState }) }

      history.push(cPath)
    }
  }

  return <div className="em-cbread">{prepareBreadCrumb(props.bArray)}</div>
}

const mapStateToProps = state => ({
  api: state.api,
  myProfile: state.myProfile,
  myUserAccountInfo: state.myUserAccountInfo,
  theme: state.theme,
  userProjectPermissions: state.userProjectPermissions
})

export default withRouter(connect(mapStateToProps)(index))
