import React from 'react'

const Progress = props => {
  return (
    <div className="rsc-progressLine">
      <div
        style={{
          width: `${props.progress}%`,
          height: '20px',
          backgroundColor: '#75af00',
          transition: 'width 0.5s'
        }}
      />
    </div>
  )
}

export default Progress
