import React from 'react'
import { connect } from 'react-redux'
import { removeNotification, showNotification } from '../../redux/notificationAction'
import './index.css'

class AlertBox extends React.Component {
  constructor() {
    super()
    this.state = {
      showNotifications: false
    }
  }
  componentDidMount() {
    this.setState({ showNotifications: this.props.notification })
  }

  componentDidUpdate(prevProps) {
    if (this.props.notification !== prevProps.notification) {
      this.setState({ showNotifications: this.props.notification }, () => {
        if (this.state.showNotifications === true) {
          setTimeout(() => {
            this.props.dispatch(showNotification(false))
            this.props.dispatch(removeNotification(this.props.currentNotification))
            this.setState({ showNotifications: this.props.notification })
          }, 7000)
        }
      })
    }
  }

  render() {
    const { showNotifications } = this.state
    const { currentNotification } = this.props
    if (showNotifications) {
      return (
        <NotificationComponent
          message={currentNotification && currentNotification.message ? currentNotification.message : ''}
          type={currentNotification && currentNotification.type}
          cancelNotication={() => {
            if (currentNotification) {
              this.props.dispatch(showNotification(false))
              this.props.dispatch(removeNotification(currentNotification))
            }
          }}
        />
      )
    } else {
      return <></>
    }
  }
}
const mapStateToProps = state => ({
  notificationList: state.notification.notificationList,
  notification: state.notification.showNotification,
  currentNotification: state.notification.currentNotification
})

export default connect(mapStateToProps)(AlertBox)

const NotificationComponent = ({ message, cancelNotication, type }) => {
  return (
    <div className={`em_down ${type}`}>
      <div className="em_content">
        {notificationTypeImage(type)}
        <div className="em_down_message" id="content_box">
          {message}
        </div>
      </div>
      <div className="em_down_close" onClick={() => cancelNotication()}>
        <i class="fa fa-times" aria-hidden="true"></i>
        Close
      </div>
    </div>
  )
}

const notificationTypeImage = key => {
  switch (key) {
    case 'success':
      return <i class="fa fa-check success" aria-hidden="true"></i>
    case 'error':
      return <i class="fa fa-exclamation-triangle error" aria-hidden="true"></i>
    case 'warning':
      return <i class="fa fa-exclamation-triangle warning" aria-hidden="true"></i>
    default:
      return <></>
  }
}
