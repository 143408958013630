import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getCurrentTimeDate } from '../../../components/functions'
import { addTaskWindow, addTaskWindowTroughtApi } from '../../redux/FrameWorkReducer'
import { addNotification, showNotification } from '../../redux/notificationAction'
import { getCurrentContextuserIdDetails } from '../Admin/AccountUtility'
import { getColor } from '../Admin/topLayerComponent'
import { getObjectByKey, makeJsons } from '../utils/commonUtilityFunctions'
import GenericPageErrorBoundary from '../utils/ErrorBoundary/GenericPageErrorBoundary'
import './index.css'

//@Author Aadil Masavir
//aadilmasavir@datoin.com
//Datoin.com
//07 May 2024

const getUpdatedJob = item => {
  return {
    status: item.status,
    completionPercentage: 0,
    numberOfItems: 0,
    hierarchy: null,
    type: 'JOB',
    name: item.name,
    id: item.id,
    orgId: null,
    spaceId: null,
    userId: item.creatorId,
    timestamp: item.createdOn,
    time: item.updatedOn
  }
}

const AppStatus = props => {
  const current_logged_user = getCurrentContextuserIdDetails(props.myUserAccountInfo.current_logged_user)

  useEffect(() => {
    if ((props.tasks !== undefined && props.tasks !== null && props.tasks !== false, Array.isArray(props.tasks))) {
      const isRunning = props.tasks.reduce((acc, next) => {
        if (next.status === 'RUNNING') {
          return [...acc, next]
        } else {
          return acc
        }
      }, [])
      if (isRunning && isRunning.length > 0)
        if (!props.myApps.isLoading) {
          pushUpdatedApps(props.myApps && props.myApps.jobs ? props.myApps.jobs : [], isRunning)
        } else if (props.myApps.isLoading) {
          checkUpdateforMyApps(isRunning)
        }
    }
  }, [])

  const checkUpdateforMyApps = isRunning => {
    const api = props.api
    if (api == null) return
    let params = { max: 100 }
    let current_conf = { projectId: current_logged_user.spaceId, orgId: current_logged_user.orgId }

    params['jobRunType'] = 'all'
    params['start'] = 0
    params['sortOrder'] = 'desc'
    params['sort'] = 'updatedOn'

    const action = api.createFetchMyAppsRSAA(params, null, current_conf)
    Promise.resolve(props.dispatch(action)).then(response => {
      const hasError = response.type.toLowerCase().endsWith('failure')
      if (!hasError) {
        pushUpdatedApps(response.payload && response.payload.jobs ? response.payload.jobs : [], isRunning)
      }
    })
  }

  const pushUpdatedApps = (apps, isRunning) => {
    isRunning &&
      isRunning.map(it => {
        const objectFound = getObjectByKey(apps, 'id', it.id)
        if (objectFound !== undefined && objectFound !== null) {
          const getTaskObject = getUpdatedJob(objectFound)
          props.dispatch(addTaskWindow({ ...makeJsons(getTaskObject), ...{ time: new Date().getTime() } }))
        } else {
          props.dispatch(addTaskWindowTroughtApi([]))
        }
      })
  }

  const navigateTo = it => {
    if (props.checkUberDeployment && props.checkUberDeployment.status === 'DEPLOY_SUCCESS') {
      const url = `/apps/${it.id}`
      const { history } = props
      props.closeNotification()
      history.push(url)
    } else {
      props.dispatch(addNotification('Please restore your services. in order to view Job Page', 'warning', false))
      props.dispatch(showNotification(true))
    }
  }

  if (props.tasks && props.tasks.length > 0) {
    return (
      <GenericPageErrorBoundary history={props.history}>
        <div className="notification-activities customScrollDsgn">
          {' '}
          {props.tasks.map(i => {
            return (
              <div className="notification-item-div">
                <div>
                  <div style={{ backgroundColor: 'grey' }} className="notification-item-avatar">
                    {i.name}
                  </div>
                </div>
                <div className="notification-item">
                  <p className="item-name"> {i.name}</p>
                  <div className="notification-status">
                    <p className="item-status-hr"> Status</p>
                    <img src={getIcon(i.status)} style={{ width: 15, height: 15 }} />
                    <p className="item-status" style={{ color: getColor(i.status) }}>
                      {i.status}
                    </p>
                  </div>
                  <div style={{ marginTop: -8 }} className="notification-go-toApps">
                    {i.type === 'JOB' && i.id !== null && (
                      <button
                        className="appstatus-emly-btn-primary"
                        onClick={() => {
                          navigateTo(i)
                        }}
                      >
                        Go To Apps
                      </button>
                    )}
                  </div>
                  <p className="item-time">{getCurrentTimeDate(i.time)}</p>
                </div>
              </div>
            )
          })}
        </div>
      </GenericPageErrorBoundary>
    )
  } else {
    return <div className="no-data">No Activities found.</div>
  }
}

export default withRouter(connect(state => state)(AppStatus))

export const getIcon = key => {
  switch (key) {
    case 'ABORTED':
      return require('../../../images/Notification/NotApplicable.png')
    case 'FAILED':
      return require('../../../images/Notification/NotApplicable.png')
    case 'RUNNING':
      return require('../../../images/Notification/inProgressGif.gif')
    case 'QUEUED':
      return require('../../../images/Notification/Info.png')
    case 'COMPLETED':
      return require('../../../images/Notification/Success.png')
    default:
      return require('../../../images/Notification/Info.png')
  }
}
