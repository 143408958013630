import { EmptyState, Grid } from 'patternfly-react'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NormalButton } from '../../ReusableCode/EmlyButton'

class GenericPageErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: null, errorInfo: null }
    this.goBack = this.goBack.bind(this)
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error('ERROR IN display ', this.props.history)
    this.setState({ hasError: true, errorInfo: errorInfo })
  }

  goBack = () => {
    this.props.history.goBack()
  }

  render() {
    if (this.state.hasError) {
      return (
        <Grid fluid={true}>
          <Grid.Row>
            <Grid.Col className="page-centre-error-content" sm={12}>
              <EmptyState>
                <i class="fa fa-exclamation-triangle text-danger fa-5x" />
                <EmptyState.Title className="" />
                <EmptyState.Info>
                  <h2 class=""> We are currently unable to handle this request</h2>
                  <p class="mt-1">
                    {this.props.history && this.props.history.length > 1 && (
                      <NormalButton onClick={this.goBack}>
                        <i class="fa fa-refresh"></i> Reload
                      </NormalButton>
                    )}
                  </p>
                </EmptyState.Info>
              </EmptyState>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      )
    }
    return this.props.children
  }
}
export default connect(state => state)(GenericPageErrorBoundary)
