import React, { useState } from 'react'
import PopUp from '../../api/components-examples/PopUP'
import './billingexpire.css'
import Select from 'react-select'



const BillingExpireContactOwner = ({ upgradePlan, setUpgradePlans  }) => {   
  const [plan, setPlan] = useState() 
  return (
    <PopUp className={'billingExpire'} title={null} loadingCancel={true} bottomButton={false} bottomButtonCancel={false} width={'680px'}>
      <div className={'em-row'}>
          <div className="billingExpire-parent">
            <div className="billingExpire-img">
              <img src={require('../../images/buyNowpurchase.png')} />
            </div>
            <div className="billingExpire-description">
              <span className="des_1"><b>Subscription Expired!</b></span>                            
              <span className="des_2">
                Your subscription has expired. Please renew to continue enjoying uninterrupted access. 
              </span>

              <span className='des_note'>
                To keep all current features, please renew by choosing the same or a higher plan.
              </span>
              <div className="try-Now em-row">
                <Select
                  value={plan}
                  onChange={value => setPlan(value)}
                  options={options}
                  placeholder="Choose Plan"
                  menuPortalTarget={document.body}  
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 })
                  }}
                />
              </div>

              <span className="des_3">              
                <a href={`${window.REACT_APP_PRICING_LINK}`} target="_blank">
                  View Plans and Pricing Details&nbsp;&nbsp;<i class="fa fa-external-link" aria-hidden="true"></i>
                </a>

                <div style={{ display: 'flex', justifyContent: 'end', gap: '10px', float: 'right', paddingTop: '16px' }}>
                  <button
                    className={'em-btn'}
                    disabled={plan == null}
                    onClick={e => {
                      upgradePlan(plan)
                    }}
                  >
                    Subscribe
                  </button>
                  <button
                    className={'em-btn em-danger'}
                    onClick={e => {
                      setUpgradePlans(false)
                    }}
                  >
                    Close
                  </button>
                </div>               
              </span>

              <span className="des_4">
                Contact us if you need any assistance with the renewal process.
              </span>
            </div>              
          </div>
        </div>
      </PopUp>
  )
}

export default BillingExpireContactOwner

const options = window.REACT_APP_PLAN_LIST.map(it => {
  return { label: it.name, value: it.planId }
})
