import React from 'react'
import './waitingLoader.css'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

const WaitingLoader = (props) => {
    const curTheme = props.theme ? props.theme : { action: { theme: 'light' } }
    return (
        <div className='em-row em-flex parent-Wloader' data-theme={curTheme && curTheme.action && curTheme.action.theme}>
            {props.message && <h3>{props.message}</h3>}
            <div class="waiting-Loader"></div>
        </div>
    )
}

const mapStateToProps = state => ({
    theme: state.theme
})

export default withRouter(connect(mapStateToProps)(WaitingLoader))
