import React, { useState } from 'react'
import PopUp from '../../api/components-examples/PopUP'
import './billingexpire.css'


const BillingExpireContactNonOwner = ({setUpgradePlans }) => {    
  return (
    <PopUp className={'billingExpire'} title={null} loadingCancel={true} bottomButton={false} bottomButtonCancel={false} width={'680px'}>
      <div className={'em-row'}>
          <div className="billingExpire-parent">
            <div className="billingExpire-img">
              <img src={require('../../images/buyNowpurchase.png')} />
            </div>
            <div className="billingExpire-description">
              <span className="des_1"><b>Subscription Expired!</b></span>                            
              <span className="des_2">
              Your access has been temporarily suspended due to an expired subscription. Please reach out to your account administrator to renew the subscription and restore access.  
              </span>

              <span className="des_3" style={{justifyContent:'flex-end'}}>              
                <button
                    className={'em-btn em-danger'}
                    onClick={e => {
                      setUpgradePlans(false)
                    }}
                  >
                    Close
                  </button>                
              </span>
            </div>              
          </div>
        </div>
      </PopUp>
  )
}

export default BillingExpireContactNonOwner