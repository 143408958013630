import React from 'react'
import './Card.css'

const Group = ({ item, long = false, onClick, width, border = true }) => {
  if (item !== undefined) {
    return (
      <div className="card group-card-outer">
        <div className="card group-card-inner">
          <div style={width && { width: width }} onClick={onClick} className={border ? 'card' : 'card-no-border'}>
            <div className="card-title">{item.title}</div>
            <div className={border ? 'card-image' : 'card-image-no-border'}>
              <img
                src={item.img_url ? item.img_url : require('../../../images/Inventory Optimization.png')}
                style={border ? {} : { width: '80%', height: '30%' }}
              />
            </div>
            <div className={border ? 'card-content-div' : 'card-content-div-no-border customScrollDsgn'}>
              <p className="card-content" title={item.description}>
                {item.description}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="card group-card-outer">
        <div className="card group-card-inner">
          <div style={width && { width: width }} onClick={onClick} className={border ? 'card' : 'card-no-border'}>
            <div className="card-title">{'no-title'}</div>
            <div className={border ? 'card-image' : 'card-image-no-border'}>
              <img
                src={require('../../../images/Inventory Optimization.png')}
                style={border ? {} : { width: '80%', height: '30%' }}
              />
            </div>
            <div className={border ? 'card-content-div' : 'card-content-div-no-border customScrollDsgn'}>
              <p className="card-content" title={'no-description'}>
                ''
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Group
