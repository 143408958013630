export const emlyFrameWork = (
  state = { taskWindow: [], project_access_removed: false, collapse: false, spec_toggle: false, projectInfoKey: 1 },
  action
) => {
  switch (action.type) {
    case 'ADD_TASKS_LIST':
      return {
        ...state,
        taskWindow: addtasks(state.taskWindow !== undefined ? state.taskWindow : [], action.payload)
      }
    case 'set-project-info-key':
      return {
        ...state,
        projectInfoKey: action.payload
      }
    case 'ADD_TASKS_LIST-TROUGH_API':
      return {
        ...state,
        taskWindow: action.payload
      }
    case 'change-collpase':
      return {
        ...state,
        collapse: !state.collapse
      }
    case 'project_access_removed':
      return {
        ...state,
        project_access_removed: action.payload
      }
    case 'set-project-flow-info-key':
      return {
        ...state,
        projectFlowInfoKey: action.payload
      }

    default:
      return state
  }
}

export function setProjectAccessRemoved(payload) {
  return {
    type: 'project_access_removed',
    payload: payload
  }
}

export function setProjectInfoKey(payload) {
  return {
    type: 'set-project-info-key',
    payload: payload
  }
}

export function changeCollpase(payload) {
  return {
    type: 'change-collpase',
    payload: payload
  }
}

export function addTaskWindow(payload) {
  return {
    type: 'ADD_TASKS_LIST',
    payload: payload
  }
}

export function addTaskWindowTroughtApi(payload) {
  return {
    type: 'ADD_TASKS_LIST-TROUGH_API',
    payload: payload
  }
}

function addtasks(tasks, task) {
  if (tasks !== undefined && tasks !== null && Array.isArray(tasks)) {
    return [...tasks, ...[task]]
  }
}

export function setProjectFlowInfoKey(payload) {
  return {
    type: 'set-project-flow-info-key',
    payload: payload
  }
}
