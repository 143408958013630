import React, { lazy, Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { getCurrentContextuserIdDetails } from '../api/components-examples/Admin/AccountUtility'
import '../api/components-examples/Admin/admin.css'
import ApiError from '../api/components-examples/APIError'
import { routes, userNFoundRoutes } from '../api/components-examples/Routes/routes'
import { OneUniqueLoaderNB } from '../api/components-examples/utils.js'
import { containsObjectWithKeyValue } from '../api/components-examples/utils/commonUtilityFunctions.js'

const CayleyExplorer = lazy(() => import('../api/components-examples/CayleyExplorer'))

export const MainContent = props => {
  const {
    projectInfoKey,
    services,
    history,
    collapseNav,
    openNav,
    collapseAppStatus,
    openAppStatus,
    myProfile,
    myUserAccountInfo,
    paths,
    userNotFound
  } = props

  const updatedRoutes = userNotFound === true ? userNFoundRoutes : routes
  const defaultPath = userNotFound === true ? '/onboarduser' : props.defaultPath

  return (
    <>
      {props.error && <ApiError error={props.error} />}
      <Switch>
        {updatedRoutes.map((route, idx) => {
          if (route.name === 'Projects' || route.name === 'ProjectInfo' || 'Organizations') {
            return (
              <Route
                exact
                path={route.path}
                key={projectInfoKey}
                render={props => (
                  <Suspense fallback={<OneUniqueLoaderNB />}>
                    {' '}
                    <route.element {...props} />{' '}
                  </Suspense>
                )}
              />
            )
          } else {
            if (
              route.element &&
              route.name === 'EmlyNoteBook' &&
              !containsObjectWithKeyValue(paths, 'name', 'emlyNoteBook')
            )
              return
            else
              return (
                route.element && (
                  <Route
                    exact
                    path={route.path}
                    key={route.name}
                    render={props => (
                      <Suspense fallback={<OneUniqueLoaderNB />}>
                        {' '}
                        <route.element {...props} />{' '}
                      </Suspense>
                    )}
                  />
                )
              )
          }
        })}

        <Route
          exact
          path="/datasets/explorer2/:organizationId/:projectId/:id"
          key="/datasets/explorer2/:organizationId/:projectId/:id"
          render={props => {
            return (
              <Suspense fallback={<OneUniqueLoaderNB />}>
                <CayleyExplorer
                  history={history}
                  collapseNav={collapseNav}
                  openNav={openNav}
                  collapseAppStatus={collapseAppStatus}
                  openAppStatus={openAppStatus}
                  {...props}
                />
              </Suspense>
            )
          }}
        />
        {/* for cot story to move from 1 cot obj to other with same url problem */}
        <Route
          exact
          path="/datasets/explorer3/:organizationId/:projectId/:id"
          key="/datasets/explorer3/:organizationId/:projectId/:id"
          render={props => {
            return (
              <Suspense fallback={<OneUniqueLoaderNB />}>
                <CayleyExplorer
                  history={history}
                  collapseNav={collapseNav}
                  openNav={openNav}
                  collapseAppStatus={collapseAppStatus}
                  openAppStatus={openAppStatus}
                  {...props}
                />
              </Suspense>
            )
          }}
        />
        {services && services.studio && services.studio.renoir && (
          <Route
            exact
            path={'/datasets/items/:id/annotate'}
            key={'/datasets/items/:id/annotate'}
            render={props => {
              const getRouterParamId = () => {
                const {
                  match: {
                    params: { id }
                  }
                } = props
                return id || null
              }
              return (
                <ExternalLinkIframe
                  name={'annotate'}
                  title={'Annotate'}
                  myUserAccountInfo={myUserAccountInfo}
                  history={history}
                  link={services.studio.renoir + '?dataitemId=' + getRouterParamId()}
                  collapseNav={collapseNav}
                  openNav={openNav}
                  collapseAppStatus={collapseAppStatus}
                  openAppStatus={openAppStatus}
                  myProfile={myProfile}
                />
              )
            }}
          />
        )}

        {props.paths &&
          props.paths.map(feature => {
            if (feature.external) {
              return (
                <Route
                  exact
                  path={'/' + feature.name}
                  key={'/' + feature.name}
                  render={props => {
                    return (
                      <ExternalLinkIframe
                        name={feature.name}
                        title={feature.title}
                        history={history}
                        link={feature.link + '?tempValue=temp'}
                        collapseNav={collapseNav}
                        openNav={openNav}
                        myUserAccountInfo={myUserAccountInfo}
                        collapseAppStatus={collapseAppStatus}
                        openAppStatus={openAppStatus}
                        myProfile={myProfile}
                      />
                    )
                  }}
                />
              )
            }
          })}

        {props.paths &&
          props.paths.map(feature => {
            if (feature.external && feature.name === 'studio') {
              return (
                <Route
                  exact
                  path={'/' + feature.name + '/:id'}
                  key={'/' + feature.name + '/:id'}
                  render={props => {
                    const getRouterParamId = () => {
                      const {
                        match: {
                          params: { id }
                        }
                      } = props
                      return id || null
                    }
                    return (
                      <ExternalLinkIframe
                        name={feature.name}
                        title={feature.title}
                        history={history}
                        link={feature.link + '?appId=' + getRouterParamId()}
                        collapseNav={collapseNav}
                        openNav={openNav}
                        myUserAccountInfo={myUserAccountInfo}
                        collapseAppStatus={collapseAppStatus}
                        openAppStatus={openAppStatus}
                        myProfile={myProfile}
                      />
                    )
                  }}
                />
              )
            }
          })}

        {defaultPath && <Redirect from="*" to={defaultPath} key="default-route" />}
      </Switch>
    </>
  )
}

class ExternalLinkIframe extends React.Component {
  componentDidMount() {
    this.props.collapseNav()
    this.props.collapseAppStatus()
  }

  componentWillUnmount() {
    this.props.openNav()
    this.props.openAppStatus()
  }

  render() {
    const { title, name, link, myUserAccountInfo } = this.props
    const NewComponent = title.split(' ').join('_').toUpperCase()

    let current_logged_user = getCurrentContextuserIdDetails(myUserAccountInfo.current_logged_user)
    return (
      <NewComponent>
        <section>
          <div className="annotator-modal" />
          <iframe
            style={{ height: 'calc(100vh)' }}
            frameborder="0"
            id={name === 'annotate' ? 'annoIframe' : ''}
            name={name}
            height="100%"
            width="100%"
            src={`${link}&spaceId=${current_logged_user.spaceId}&orgId=${current_logged_user.orgId}`}
          />
        </section>
      </NewComponent>
    )
  }
}
