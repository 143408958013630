import { css } from '@emotion/core'
import React from 'react'
import BarLoader from 'react-spinners/BarLoader'
// Another way to import. This is recommended to reduce bundle size
import GridLoader from 'react-spinners/GridLoader'
// First way to import
import PropagateLoader from 'react-spinners/PropagateLoader'
import WaitingLoader from '../../UI-components/Templates/WaitingLoader'

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`

export class Loading extends React.Component {
  render() {
    const defaultConfig = { color: '#2D3748', type: 'Grid', height: 40, width: 40 }
    const config = { ...defaultConfig, ...this.props }

    return <GridLoader {...config} />
  }
}

export class LoadingReplica extends React.Component {
  render() {
    const defaultConfig = { color: '#2D3748', type: 'Grid', height: 40, width: 40 }
    const config = { ...defaultConfig, ...this.props }

    return (
      <div class="centreLoadingSpinner">
        <GridLoader {...config} />
      </div>
    )
  }
}

export class LoadingFullScreen extends React.Component {
  render() {
    const defaultConfig = { color: '#2D3748', type: 'Grid', height: 40, width: 40 }
    const config = { ...defaultConfig, ...this.props }

    return (
      <div className="cover-body-full">
        <div class="o-p-L-ic-ld">
          <GridLoader {...config} />
          <strong style={{ marginTop: '14px', fontSize: '15px' }}>{this.props.msg && this.props.msg}</strong>
        </div>
      </div>
    )
  }
}

export class OneUniqueLoaderBG extends React.Component {
  render() {
    const defaultConfig = { color: '#2D3748', type: 'Grid', height: 40, width: 40 }
    const config = { ...defaultConfig, ...this.props }
    return (
      <div class="o-p-L-ic-ld">
        <GridLoader {...config} />
        <strong style={{ marginTop: '14px', fontSize: '15px' }}>{this.props.msg && this.props.msg}</strong>
      </div>
    )
  }
}

export class LoaderBGWithinComponent extends React.Component {
  render() {
    const defaultConfig = { color: '#2D3748', type: 'Grid', height: 40, width: 40 }
    const config = { ...defaultConfig, ...this.props }
    return (
      <div class="o-p-L-ic-wc">
        <GridLoader {...config} />
        <strong style={{ marginTop: '14px', fontSize: '15px' }}>{this.props.msg && this.props.msg}</strong>
      </div>
    )
  }
}

export class LoaderBGWithinComponentBlurOne extends React.Component {
  render() {
    const defaultConfig = { color: '#2D3748', type: 'Grid', height: 40, width: 40 }
    const config = { ...defaultConfig, ...this.props }
    return (
      <div class="o-p-L-ic-wc" style={{ backdropFilter: 'blur(2px)', opacity: 0.5 }}>
        <GridLoader {...config} />
        <strong style={{ marginTop: '14px', fontSize: '15px' }}>{this.props.msg && this.props.msg}</strong>
      </div>
    )
  }
}

export class OneUniqueLoaderNB extends React.Component {
  render() {
    const defaultConfig = { color: '#2D3748', type: 'Grid', height: 40, width: 40 }
    const config = { ...defaultConfig, ...this.props }

    return (
      <div style={{ backgroundColor: 'blur(8px)' }} className="o-p-L-ic-md">
        <GridLoader {...config} />
        <strong class="mt-2">{this.props.msg && this.props.msg}</strong>
      </div>
    )
  }
}

export class ButtonLoading extends React.Component {
  render() {
    const defaultConfig = { color: '#9E9E9E', height: 20, width: 60 }
    const config = { ...defaultConfig, ...this.props }

    return <BarLoader {...config} />
  }
}

export class NavDataLoading extends React.Component {
  render() {
    const defaultConfig = { color: '#2D3748', type: 'Grid', height: 40, width: 40 }
    const config = { ...defaultConfig, ...this.props }

    return (
      <div className="centreLoadingSpinner" style={{ height: 'calc( 100vh - 65px )' }}>
        <GridLoader {...config} />
      </div>
    )
  }
}

export class MyAppsLoading extends React.Component {
  render() {
    const defaultConfig = { color: '#2D3748', height: 40, width: 80, type: 'Grid' }
    const config = { ...defaultConfig, ...this.props }

    return (
      <div className="centreLoadingData" style={{ height: 'calc( 5vh )', overflow: 'hidden' }}>
        <PropagateLoader {...config} />
      </div>
    )
  }
}

export class WaitingLoading extends React.Component {
  render() {
    return (
      <div className="waitingLoaderParent">
        <WaitingLoader message={this.props.msg} />
      </div>
    )
  }
}
