import { SIGNIN_FAILURE, SIGNIN_REQUEST, SIGNIN_SUCCESS, SIGNOUT_REQUEST } from './actionTypes'

export default function (state = { keycloak: null, isAuthenticated: false, isLoading: false }, action) {
  const _keycloak = action.payload != null && action.payload.keycloak != null ? action.payload.keycloak : null
  switch (action.type) {
    case SIGNIN_REQUEST:
      return {
        keycloak: _keycloak,
        isAuthenticated: state.isAuthenticated,
        isLoading: true
      }
    case SIGNIN_SUCCESS:
      return {
        keycloak: _keycloak,
        isAuthenticated: true,
        isLoading: false
      }
    case SIGNIN_FAILURE:
      return {
        keycloak: _keycloak,
        isAuthenticated: false,
        isLoading: false
      }

    case SIGNOUT_REQUEST:
      if (window.logoutUrl) {
        window.location = window.logoutUrl
      }
      new Promise(resolve => setTimeout(resolve, 5000))
      return {
        keycloak: null,
        isAuthenticated: false,
        isLoading: false
      }
  }
  return state
}
