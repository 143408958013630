import React from 'react'
import ProfileMenu from '../Admin/MenuComponent/ProfileMenu'
import '../UserSignup/index.css'
import { ErrorScreen } from './ErrorScreen'
import './start.css'
import WaitingLoader from '../../../UI-components/Templates/WaitingLoader'

const profileItems = (firstName, familyName) => {
  return [
    {
      title: `${firstName}${familyName}`,
      root: true,
      label: 'User',
      submenu: [
        {
          title: 'Profile',
          url: '/profile',
          type: 'link',
          disabled: true
        },
        {
          title: 'Log out',
          type: 'link'
        }
      ]
    }
  ]
}

class Start extends React.Component {
  render() {
    const isperSisted =
      this.props.persisted &&
      this.props.persisted.userInformation !== null &&
      this.props.persisted.userInformation !== undefined
        ? this.props.persisted.userInformation
        : { name: 'U', family_name: '' }
    const firstName = isperSisted && isperSisted.name ? isperSisted.name.charAt(0) : 'U'
    const familyName = isperSisted && isperSisted.family_name ? isperSisted.family_name.charAt(0) :''

    return (
      <React.Fragment>
        <div className="start-org-creation">
          <div className="em-org-cr-flex">
            <div className="em-row org-logout">
              <div className="topicons">
                <ProfileMenu
                  disabled={false}
                  menuItems={profileItems(firstName, familyName)}
                  dispatch={() => {}}
                  showSwitch={() => {}}
                />
              </div>
            </div>
            {this.props.error !== null ? (
              <ErrorScreen buttonText={'Retry'} error={this.props.error} onResolve={this.props.onResolve} />
            ) : (
              <div className="start-org-content">
                <div className="centreLoadingSpinner">                  
                  <WaitingLoader message={`Welcome ${isperSisted && isperSisted.name}. Please wait, We are creating your organization!`}/>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default Start
