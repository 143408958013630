import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NormalButton } from '../../ReusableCode/EmlyButton'

class MainErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: null, errorInfo: null }
    this.goBack = this.goBack.bind(this)
  }
  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true, errorInfo: errorInfo })
  }

  goBack = () => {
    this.props.history.goBack()
    setTimeout(() => {
      this.reload()
    }, 2000)
  }

  reload = () => {
    window.location = '/'
  }

  render() {
    if (this.state.hasError || this.props.error) {
      return (
        <div className="em-row ErrorBParent">
          <div className='ErrorB'>
            <div className="em-flex" style={{ gap: '6px', justifyContent: 'start' }}>
              <svg viewBox="0 0 64 64">
                <g id="Flat">
                  <path
                    d="m53.8 59h-43.6a7.2 7.2 0 0 1 -7.2-7.2 7.2 7.2 0 0 1 .847-3.388l20.8-39a8.332 8.332 0 0 1 7.353-4.412 8.332 8.332 0 0 1 7.353 4.412l20.8 39a7.2 7.2 0 0 1 .847 3.388 7.2 7.2 0 0 1 -7.2 7.2z"
                    fill="#7586a6"
                  />
                  <path
                    d="m10.2 59h43.6a7.2 7.2 0 0 0 6.351-10.586l-20.8-39a8.188 8.188 0 0 0 -.535-.855 8.3 8.3 0 0 0 -12.171 2.855l-20.8 39a7.2 7.2 0 0 0 .361 7.378 7.168 7.168 0 0 0 3.994 1.208z"
                    fill="#5a6e8c"
                  />
                  <path
                    d="m10.2 55a3.2 3.2 0 0 1 -2.821-4.7l20.8-39a4.332 4.332 0 0 1 7.646 0l20.8 39a3.2 3.2 0 0 1 -2.825 4.7z"
                    fill="#ffc83e"
                  />
                  <path
                    d="m35.907 11.451a4.311 4.311 0 0 0 -5.73 1.844l-20.8 39a3.209 3.209 0 0 0 -.377 1.505 3.156 3.156 0 0 0 .181 1.017 3.156 3.156 0 0 0 1.019.183h43.6a3.2 3.2 0 0 0 2.822-4.7z"
                    fill="#ffdc5e"
                  />
                  <g fill="#5a6e8c">
                    <path d="m32 21a2.853 2.853 0 0 1 2.85 3l-.75 15a2.1 2.1 0 0 1 -2.1 2 2.1 2.1 0 0 1 -2.1-2l-.75-15a2.853 2.853 0 0 1 2.85-3z" />
                    <circle cx="32" cy="47" r="2" />
                  </g>
                </g>
              </svg>
              <h1>Service Disruption Detected</h1>
            </div>
            <div className="inn-cop">
              We've detected a disruption that may be due to a software update or a service issue. Please try reloading the page, and if the issue persists, contact us at <b style={{ fontFamily: 'redHatBold' }}>support@emlylabs.com</b>
            </div>
            <div className="em-row err-reload">
              <NormalButton
                className="em-btn"
                onClick={() => window.location.reload(true)}
                title={'Reload'}
              >
                <i class="fa fa-refresh"></i> Reload
              </NormalButton>
            </div>
          </div>
        </div>
      )
    }
    return this.props.children
  }
}
export default connect(state => state)(MainErrorBoundary)
