import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { capitalizeFirstLetter } from '../../../components/functions'
import { Bar, DatoinLogo } from '../../../VerticalNavigation'
import { changeCollpase, setProjectInfoKey } from '../../redux/FrameWorkReducer'
import '../ReusableCode/emlyMain.css'
import { getCurrentContextuserIdDetails } from './AccountUtility'
import ProfileMenu from './MenuComponent/ProfileMenu'
import NotificationPanel from './NotificationPanel'
import OrganizationDropDown from './OrganizationDropDown'
import './topLayerComponent.css'

function TopLayerInternal(props) {
  const [notificationpanel, setNotificationPanel] = useState(false)
  const [active, setActive] = useState('Activities')
  const [removeIds, setRemoveIds] = useState([])

  const isperSisted = props.userProfilePersisted.userInformation !== null
  const firstName =
    isperSisted &&
    props.userProfilePersisted.userInformation.name !== undefined &&
    props.userProfilePersisted.userInformation.name.charAt(0)
  const familyName =
    isperSisted && props.userProfilePersisted.userInformation.family_name !== undefined
      ? props.userProfilePersisted.userInformation.family_name.charAt(0)
      : ''
  const profileItems = [
    {
      title: props.userProfilePersisted.userInformation !== null ? `${firstName}${familyName}` : 'U',
      root: true,
      label:
        props.userProfilePersisted.userInformation !== null ? props.userProfilePersisted.userInformation.name : 'User',
      submenu: [
        {
          title: 'Switch Org',
          type: 'link',
          submenu: [
            {
              type: 'component',
              title: organizationComponent(props.errorPanel, props.showSwitch)
            }
          ]
        },
        {
          title: 'Profile',
          url: '/profile',
          type: 'link'
        },
        {
          title: 'Log out',
          type: 'link'
        }
      ]
    }
  ]

  const navigateTo = path => {
    props.history.push(path)
  }

  const current_logged_user = getCurrentContextuserIdDetails(props.myUserAccountInfo.current_logged_user)

  return (
    <>
      <div
        data-theme={props.theme}
        className="topbarr"
      >
        <div className="project-head">
          <div className="logo-div">
            <Bar
              curTheme={props.theme}
              collapse={props.collapse}
              handleCollpase={() => {
                props.dispatch(changeCollpase())
              }}
            />
            <DatoinLogo curTheme={props.theme} collapse={props.collapse} />
          </div>
          <div className={'project-head-row'}>
            <div
              title="Active Project"
              onClick={() => {
                if (current_logged_user.spaceId === 'undefined') {
                  navigateTo(`/projects`)
                } else {
                  navigateTo(
                    `/${current_logged_user.orgId}/${current_logged_user.spaceId}/projects/${current_logged_user.spaceId}`
                  )
                }
                props.dispatch(setProjectInfoKey(new Date().getTime()))
              }}
            >
              <div className={`projectHTitle txtHover`}>
                <svg
                  width="25"
                  height="20"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  id="emly-icn phome"
                >
                  <rect width="28" height="28" rx="6" fill="var(--themeColor)" />
                  <path d="M21.2049 10.4026V7.67969H18.4819L21.2049 10.4026Z" fill="white" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.6582 7.33935C17.7962 7.00625 18.1212 6.78906 18.4818 6.78906H21.2047C21.697 6.78906 22.0961 7.18816 22.0961 7.68048V10.4034C22.0961 10.764 21.879 11.089 21.5459 11.227C21.2128 11.365 20.8293 11.2887 20.5744 11.0338L17.8514 8.3108C17.5965 8.05586 17.5202 7.67244 17.6582 7.33935Z"
                    fill="white"
                  />
                  <path
                    d="M23.6611 15.2735L14.5846 5.28364C14.4201 5.10211 14.1875 5 13.9436 5C13.6996 5 13.4671 5.10211 13.3025 5.28364L4.22601 15.2735C3.99342 15.5288 3.9367 15.8975 4.07284 16.2095C4.21467 16.5215 4.521 16.7257 4.86704 16.7257H5.8144V22.7219C5.8144 23.2041 6.20583 23.5899 6.68235 23.5899H12.1283C12.6105 23.5899 12.9962 23.1984 12.9962 22.7219V18.9381H14.8909V22.7219C14.8909 23.2041 15.2824 23.5899 15.7589 23.5899H21.2048C21.687 23.5899 22.0727 23.1984 22.0727 22.7219V16.7314H23.0201C23.3661 16.7314 23.6781 16.5272 23.8143 16.2152C23.9561 15.8975 23.8937 15.5288 23.6611 15.2735Z"
                    fill="white"
                  />
                </svg>
                {current_logged_user.spaceIdName !== undefined
                  ? capitalizeFirstLetter(current_logged_user.spaceIdName)
                  : ''}
              </div>
            </div>
          </div>
        </div>
        <div className="profile-icons-div">
          <div className="topicons">
            {
              <NotificationPanel
                notificationpanel={notificationpanel}
                setNotificationPanel={setNotificationPanel}
                dispatch={props.dispatch}
                removeIds={removeIds}
                setRemoveIds={setRemoveIds}
                active={active}
                disabled={props.myUserAccountInfo.current_logged_user === undefined}
                tasks={props.tasks}
                setActive={setActive}
                subscriptionUsage={props.subscriptionUsage}
                checkoutBilling={e => {
                  navigateTo(e)
                  props.dispatch(setProjectInfoKey(new Date().getTime()))
                }}
                myProfile={props.myProfile}
              />
            }

            <ProfileMenu
              disabled={false}
              menuItems={profileItems}
              dispatch={props.dispatch}
              showSwitch={props.showSwitch}
              checkUberDeployment={props.checkUberDeployment}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  api: state.api,
  myProfile: state.myProfile,
  checkUberDeployment: state.checkUberDeployment,
  notificationList: state.notification.saveNotifications,
  bgStatus: state.bgStatus,
  myApps: state.myApps,
  appItreationDetail: state.appItreationDetail,
  myUserAccountInfo: state.myUserAccountInfo,
  collapse: state.emlyFrameWork.collapse,
  subscriptionUsage: state.subscriptionUsage
})

export default withRouter(connect(mapStateToProps)(TopLayerInternal))

export const getColor = key => {
  switch (key) {
    case 'ABORTED':
      return 'red'
    case 'FAILED':
      return 'red'
    case 'RUNNING':
      return 'blue'
    case 'QUEUED':
      return 'red'
    case 'COMPLETED':
      return 'green'
    default:
      return 'gray'
  }
}

const organizationComponent = (errorInPanel, showSwitch) => {
  return (
    <div className="organizationDrop-down">
      <div className="OrgSelector">
        <OrganizationDropDown errorPanel={errorInPanel} showSwitch={showSwitch} />
      </div>
    </div>
  )
}
