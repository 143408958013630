import { GET_ITEM_STATUS_LIST } from './actionTypes.js'

const initialState = {
  statusList: []
}

export default function (state = initialState, actions) {
  switch (actions.type) {
    case GET_ITEM_STATUS_LIST: {
      return {
        statusList: actions.payload.statusList
      }
    }
    default:
      return state
  }
}
