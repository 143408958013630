import React, { useEffect, useState } from 'react'
import TickIcon from '../../../images/myApps/tickIcon.png'

const CpInvite = props => {
  const [memberList, setMemberList] = useState([])

  const updateTaskNameByIndex = (index, content) => {
    setMemberList(prevMembersList => {
      const updatedMList = [...prevMembersList]
      updatedMList[index] = content
      return updatedMList
    })
  }

  const retryCreateUser = (it, index) => {
    const updatedList = { status: 'LOAD', content: it.content, title: it.title }
    updateTaskNameByIndex(index, updatedList)

    let promises = []
    promises.push(
      new Promise(async function (resolve, reject) {
        if (it.step === 1) {
          props.retryCreateProject(
            it.content.cUser,
            it.content.it,
            it.content.roleName,
            it.content.orgId,
            it.content.body,
            it.content.groupId,
            it.title,
            resolve,
            reject,
            true
          )
        } else if (it.step === 2) {
          props.retryAddProjectToGroup(
            it.content.cUser,
            it.content.it,
            it.content.roleName,
            it.content.groupId,
            it.content.projectId,
            it.content.orgId,
            it.title,
            resolve,
            reject,
            true
          )
        } else if (it.step === 3) {
          props.retryInviteUser(
            it.content.it,
            it.content.roleName,
            it.content.projectId,
            it.content.orgId,
            it.title,
            resolve,
            reject,
            true
          )
        }
      })
    )

    Promise.allSettled(promises)
      .then(it => {
        it.map(item => {
          if (item.status === 'fulfilled') {
            const updatedList = {
              status: 'PASS',
              content: item.value.data,
              title: item.value['title'],
              step: item.value.step
            }
            updateTaskNameByIndex(index, updatedList)
          } else {
            const updatedList = {
              status: 'FAIL',
              content: item.reason.data,
              title: item.reason['title'],
              step: item.reason.step
            }
            updateTaskNameByIndex(index, updatedList)
          }
        })
      })
      .catch(e => {
        console.log('Error occured while Creating Projects for Users.', e)
      })
  }

  useEffect(() => {
    if (props.memberList) {
      setMemberList(props.memberList)
    }
  }, [props.memberList])

  return (
    <>
      {memberList && memberList.length > 0 ? (
        <>
          <h1 className="ul-pcgroup">{props.projectTitle}</h1>
          <h2 className="ul-pcgroup">Inviting Group to the project</h2>

          <ol className="ul-pc-group em-oneScroll">
            {(() => {
              let settings = []
              memberList.map((it, index) => {
                const title = it && it.title ? it.title : []
                settings.push(
                  <>
                    <li>
                      <p className="cp-invite-title">{title}</p>
                      <div className="img-Icon">
                        {it.status === 'LOAD' ? (
                          <p style={{ width: '16px', height: '16px' }} className="spinner"></p>
                        ) : it.status === 'PASS' ? (
                          <>
                            <img src={TickIcon}></img>
                          </>
                        ) : it.status === 'FAIL' ? (
                          <div
                            style={{
                              cursor: 'pointer',
                              width: 'auto',
                              display: 'flex',
                              alignItems: 'center',
                              gap: '5px',
                              color: 'red',
                              justifyContent: 'end'
                            }}
                            onClick={() => retryCreateUser(it, index)}
                          >
                            <i
                              class="fa fa-repeat"
                              style={{ color: 'red', cursor: 'pointer', marginRight: 0 }}
                              aria-hidden="true"
                            ></i>
                            Retry
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </li>
                  </>
                )
              })
              return settings.map(it => it)
            })()}
          </ol>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default CpInvite
