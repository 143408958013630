const customErrorMiddleware = store => next => action => {
  if (action && action.type && action.type.toLowerCase().endsWith('failure')) {

    const isType = ['FETCH_DATASET_MAX_UPLOAD', 'CREATE_A_NEW_PROJECT', 'BUILD_APP_TEMPLATE', 'FETCH_ITR_SAMPLE_MODEL'].some(prefix =>
      action.type.toUpperCase().startsWith(prefix)
    )

    const response = action.payload && action.payload.response;

    /* Handling Uber Service Down from middleware.*/
    if (isType && response && response.deploymentType === 'UBER_DEPLOYMENT' && response.status === 'NOT_FOUND') {
      store.dispatch({ type: 'FETCH_DOWN_UBER_SUCCESS', payload: { status: 'SHOWUBERISDOWN' } });
    }

    /* Handling User deletion from Organizations.*/
    if (
      response &&
      response.message &&
      response.message.startsWith("No permissions found for user having userId") &&
      response.message.includes("orgId") &&
      response.message.includes("spaceId")
    ) {
      store.dispatch({ type: 'ROLES_UPDATED_SUCCESS', payload: { status: 'ROLE_DOWNGRADED' } });
    }

    /* Handling Uber Deployment Failure*/
    if (response && response.deploymentType === 'UBER_DEPLOYMENT' &&
      (response.status === 'NOT_FOUND' || response.status === 'DEPLOY_FAILED')) {
      store.dispatch({ type: 'FETCH_UBER_DEPLOYMENT_STATUS_SUCCESS', payload: { status: response.status, isLoading: false } });
    }
    
    /* Handling INVALID_BILLING*/
    if(response && response.errors && response.errors.includes('INVALID_SUBSCRIPTION')){      
      store.dispatch({ type: 'BILLING_RENEWAL_CHECK_SUCCESS', payload: { isPlan: 'INVALID_SUBSCRIPTION', isLoading: false } });
    }
  }

  return next(action)
}
export default customErrorMiddleware
