import React from 'react'
import { GridLoader } from 'react-spinners'
import '../../../emlyDashboard.css'

export const OverLayLodingComponent = props => {
  const defaultConfig = { color: '#2D3748', type: 'Grid', height: 40, width: 40 }
  const config = { ...defaultConfig, ...props }
  return (
    <div className="emly-overlay">
      <GridLoader {...config} />
      <strong>{props.message && props.message}</strong>
    </div>
  )
}

export const OverLayLodingInsideComponent = props => {
  const defaultConfig = { color: '#2D3748', type: 'Grid', height: 40, width: 40 }
  const config = { ...defaultConfig, ...props }
  return (
    <div className="emly-overlay-wtc">
      <GridLoader {...config} />
      <strong>{props.message && props.message}</strong>
    </div>
  )
}

export const OverLayLodingInsideComponentWithbg = props => {
  const defaultConfig = { color: '#2D3748', type: 'Grid', height: 40, width: 40, backgroundColor: '#fff' }
  const config = { ...defaultConfig, ...props }
  return (
    <div className="emly-overlay-wtc">
      <GridLoader {...config} />
      <strong>{props.message && props.message}</strong>
    </div>
  )
}
