import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import '../api/components-examples/Admin/admin.css'
import Signup from '../api/components-examples/UserSignup/index'
import { OneUniqueLoaderNB } from '../api/components-examples/utils'

export const PublicRoutes = props => {
  return (
    <Switch>
      <Route
        exact
        path="/signup"
        key={'/signup'}
        render={props => (
          <Suspense fallback={<OneUniqueLoaderNB />}>
            {' '}
            <Signup {...props} />{' '}
          </Suspense>
        )}
      />
    </Switch>
  )
}
