import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { RunningInstance } from '../../../UI-components/Restore/RunningInstance'
import { getCurrentContextuserIdDetails } from '../Admin/AccountUtility'
import { blockedNotificationPaths, uberRunningStates } from '../utils/commonUtilityFunctions'
import './index.css'

const UberContainer = props => {
  const [ubContainer, setUbContainer] = useState([])
  const intervalRef = useRef(null)
  const [instanceType, setInstanceType] = useState('uber.container')

  const current_logged_user = getCurrentContextuserIdDetails(props.myUserAccountInfo.current_logged_user)

  const clearCurrentInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }
  }

  useEffect(() => {
    return () => clearCurrentInterval()
  }, [])

  const checkAllowedSize = current_logged_user => {
    const api = props.api
    if (api === null) return
    if (current_logged_user.spaceId !== undefined || current_logged_user.spaceId !== null)
      props.dispatch(api.createDatasetUploadMaxSizeRSAA(current_logged_user))
  }

  useEffect(() => {
    if (props.checkUberDeployment && uberRunningStates.includes(props.checkUberDeployment.status)) {
      clearInterval(intervalRef.current)
      intervalRef.current = setInterval(() => {
        fetchUberDeployment()
      }, 10000)
    }
  }, [props.checkUberDeployment])

  useEffect(() => {
    if (props.checkUberDeployment && !props.checkUberDeployment.isLoading) {
      setUbContainer(props.checkUberDeployment)
    }
  }, [props.checkUberDeployment])

  const navigateTo = path => {
    const { history } = props
    history.push(path)
  }

  const fetchUberDeployment = () => {
    const api = props.api

    if (api === null) return

    const action = api.createFetchUberDeploymentRSAA(current_logged_user.orgId, false, current_logged_user.spaceId)

    Promise.resolve(props.dispatch(action)).then(response => {
      const hasError = response.type.toLowerCase().endsWith('failure')
      if (!hasError) {
        if (response.payload.status === 'DEPLOY_FAILED') {
          clearCurrentInterval()
          navigateTo('/orgInstance')
        } else if (response.payload.status === 'DEPLOY_SUCCESS') {
          checkAllowedSize(current_logged_user)
          clearCurrentInterval()
        }
      } else {
        clearCurrentInterval()
        navigateTo('/orgInstance')
      }
    })
  }
  if (blockedNotificationPaths(current_logged_user).includes(props.location.pathname) === false)
    return ubContainer && uberRunningStates.includes(ubContainer.status) ? (
      <div className="uber-navigator" onClick={() => navigateTo('/orgInstance')}>
        <div className="rs-em-sc-wrapper">
          <div className="rs-em-sc-content">
            <RunningInstance uberService={ubContainer} instanceType={instanceType} />
          </div>
        </div>
      </div>
    ) : (
      <></>
    )
  else return <></>
}

const mapStateToProps = state => ({
  api: state.api,
  checkUberDeployment: state.checkUberDeployment,
  myUserAccountInfo: state.myUserAccountInfo
})

export default withRouter(connect(mapStateToProps)(UberContainer))
