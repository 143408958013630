export const emlycreateFetchMyNewDatasetsRSAAReducer = (
  state = { dataset: [], datsetLoad: false, refresh_dataset: false },
  action
) => {
  switch (action.type) {
    case 'SAVE_DATSET':
      return {
        ...state,
        dataset: action.payload
      }
    case 'LOAD_DATASET':
      return {
        ...state,
        datsetLoad: action.payload
      }
    case 'REFRESH_DATSET_COMPONENT':
      return {
        ...state,
        refresh_dataset: action.payload
      }
    default:
      return state
  }
}

export const loadDatset = text => {
  return {
    type: 'LOAD_DATASET',
    payload: text
  }
}

export const saveDataset = text => {
  return {
    type: 'SAVE_DATSET',
    payload: text
  }
}

export const refreshDataset = text => {
  return {
    type: 'REFRESH_DATSET_COMPONENT',
    payload: text
  }
}
