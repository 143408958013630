export function userProfilePersistedToken(state = { token: null, expTime: null }, action) {
  switch (action.type) {
    case 'FETCH_MY_TOKEN':
      return {
        ...state,
        token: action.payload,
        expTime: null
      }
  }

  return state
}

export function userProfilePersisted(state = { userInformation: null, userId: null }, action) {
  switch (action.type) {
    case 'FETCH_MY_PROFILE':
      return {
        ...state,
        userInformation: action.payload
      }
    case 'FETCH_MY_PROFILE_ID':
      return {
        ...state,
        userId: action.payload
      }
  }
  return state
}

export function setUserProfileID(payload) {
  return {
    type: 'FETCH_MY_PROFILE_ID',
    payload: payload
  }
}

export function setUserProfile(payload) {
  return {
    type: 'FETCH_MY_PROFILE',
    payload: payload
  }
}

export function setUserToken(payload) {
  return {
    type: 'FETCH_MY_TOKEN',
    payload: payload
  }
}
