import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { getCurrentContextuserIdDetails } from './AccountUtility.js'

const ProjectAccessRemoveAlert = props => {
  const [uistate, setUiState] = React.useState(null)
  const current_logged_user = getCurrentContextuserIdDetails(props.myUserAccountInfo.current_logged_user)
  const noOrg =
    props.myUserAccountInfo.message !== undefined && props.myUserAccountInfo.rolesUser === undefined ? true : false
  const org =
    props.myUserAccountInfo.rolesUser !== undefined &&
    props.myUserAccountInfo.rolesUser.organizations.find(i => i.id === current_logged_user.orgId)
  const navigateTo = noOrg
    ? 'noOrg'
    : org !== undefined
    ? org.spaces !== undefined && org.spaces.filter(i => i.id !== current_logged_user.spaceId).length > 0
      ? '/projects'
      : '/organizations'
    : '/organizations'
  const removeid = () => {
    if (navigateTo === '/projects') {
      window.location = '/projects'
    } else {
      window.location = '/organizations'
    }
  }
  const title = navigateTo === 'noOrg' ? 'noOrg' : navigateTo === '/organizations' ? 'Organization' : 'Project'
  const currentUrl = window.location.href
  const samepageOrgPage = currentUrl.search('organizations') !== -1
  const samepageProPage = currentUrl.endsWith('projects')
  const samepage = samepageOrgPage || samepageProPage
  return (
    <>
      {constructMessage(
        samepageProPage,
        samepageOrgPage,
        navigateTo,
        title,
        noOrg,
        current_logged_user.spaceIdName,
        () => removeid()
      )}
    </>
  )
}

const mapStateToProps = state => ({
  api: state.api,
  myProfile: state.myProfile,
  checkUberDeployment: state.checkUberDeployment,
  theme: state.theme,
  myUserAccountInfo: state.myUserAccountInfo
})

export default withRouter(connect(mapStateToProps)(ProjectAccessRemoveAlert))

const constructMessage = (samepageProPage, sameOrgPage, navigateTo, title, noOrg, project, removeid) => {
  console.log(sameOrgPage, 'checktitle')
  switch (title) {
    case 'noOrg':
      return !sameOrgPage ? (
        <div className="instance_down">
          <i className="fa fa-times-circle-o" aria-hidden="true"></i>
          {`Your access from ${project} have been revoked.
        Please create your organization or  select an organization to continue`}
          {
            <div className="restore" onClick={() => removeid()}>
              Okay
            </div>
          }
        </div>
      ) : null
    case 'Organization':
      return (
        <div className="instance_down">
          <i className="fa fa-times-circle-o" aria-hidden="true"></i>
          {`Your access from ${project} have been revoked.
        Please create your organization or  select an organization to continue`}
          {!sameOrgPage && (
            <div className="restore" onClick={() => removeid()}>
              Okay
            </div>
          )}
        </div>
      )
    case 'Project':
      return (
        <div className="instance_down">
          <i className="fa fa-times-circle-o" aria-hidden="true"></i>
          {`Your access from ${project} have been revoked.Please select an project to continue`}
          {!samepageProPage && (
            <div className="restore" onClick={() => removeid()}>
              Okay
            </div>
          )}
        </div>
      )
    default:
      break
  }
}
