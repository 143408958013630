const environment = window.REACT_APP_GATEWAY_AUTH_REALM

const encodedRedirectUri = (planId,addonProdId) => {
  let queryStr = `&prodId=${planId}`
  if(addonProdId !== null && addonProdId !== undefined)
    queryStr = `&prodId=${planId}&may_buy_addon_prodid=${addonProdId}`
  return encodeURIComponent(`${window.REACT_APP_DASHBOARD_URL}/onboarduser?ss=google${queryStr}`)
}

export const getGoogleLink = (planId,addonProdId) => {
  return `${environment.url}/realms/${environment.realm}/protocol/openid-connect/auth?client_id=${
    environment.clientId
  }&redirect_uri=${encodedRedirectUri(planId,addonProdId)}&response_type=code&scope=openid&kc_idp_hint=google`
}

export const getlinkedinLink = (planId,addonProdId) => {
  return `${environment.url}/realms/${environment.realm}/protocol/openid-connect/auth?client_id=${
    environment.clientId
  }&redirect_uri=${encodedRedirectUri(planId,addonProdId)}&response_type=code&scope=openid&kc_idp_hint=linkedin-openid-connect`
}
