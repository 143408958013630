import React, { useState } from 'react'
import './emlyButton.css'

export const NormalButton = ({ children, style, onClick, className, title, id }) => {
  return (
    <button className={className} style={style} onClick={onClick} title={title} id={id}>
      {children}
    </button>
  )
}

export const AnimatedButton = ({ children, style, onClick, className, title, id }) => {
  const [shake, setShake] = useState(false)

  const handleclick = () => {
    setShake(true)
    setTimeout(() => setShake(false), 1000)
    onClick()
  }

  return (
    <button
      className={shake ? `${className} shake` : className}
      style={style}
      onClick={handleclick}
      title={title}
      id={id}
    >
      {children}
    </button>
  )
}
