import React from 'react'

class ApiErrorDisplay extends React.Component {
  render() {
    const {
      error: { message }
    } = this.props

    if (message == null) return <span />

    return <span />
  }
}

export default ApiErrorDisplay
