import React from 'react'
import './Card.css'

const Card = ({ item, onClick, width, border = true, isLocked }) => {
  if (item !== undefined) {
    if (item.flow_id === 'blank_project') {
      return (
        <div onClick={onClick} className="card-blank">
          <div className="circle">
            <i class="fa fa-plus"></i>
          </div>
          <div className="card-title-blank">{item.label}</div>
        </div>
      )
    } else {
      return (
        <div style={width && { width: width }} onClick={onClick} className={border ? 'card' : 'card-no-border'}>
          {isLocked && (
            <svg
              className="card-tick-Icon"
              xmlns="http://www.w3.org/2000/svg"
              aria-label="Lock"
              viewBox="0 0 512 512"
              id="lock"
            >
              <title>Not available in this plan.</title>
              <rect width="512" height="512" fill="var(--themeColor)" rx="15%"></rect>
              <g fill="#29303d">
                <path
                  stroke="#ffffff"
                  stroke-width="32"
                  d="M346 214v-37c0-123-180-123-180 0v37"
                  fill="var(--themeColor)"
                ></path>
                <rect width="280" height="210" x="115" y="210" fill="#ffffff" rx="5%"></rect>
                <circle cx="256" cy="296" r="28" fill="var(--themeColor)"></circle>
                <path d="M262 296h-12l-16 69h44" fill="var(--themeColor)"></path>
              </g>
            </svg>
          )}
          <div className="card-title">{item.label}</div>
          <div className={border ? 'card-image' : 'card-image-no-border'}>
            <img
              src={item.image_url ? item.image_url : require('../../../images/Inventory Optimization.png')}
              style={border ? {} : { width: '80%', height: '30%' }}
            />
          </div>
          <div className={border ? 'card-content-div' : 'card-content-div-no-border customScrollDsgn'}>
            <p className="card-content" title={item.Description}>
              {item.Description}
            </p>
          </div>
        </div>
      )
    }
  } else {
    return <></>
  }
}

export default Card
