import React from 'react'

export const SignUpLeftLearner = props => {
  return (
    <div className="content-div signup">
      <div className="all-content">
        {props.invitationCode === null && (
          <div className="top-content em-row">
            <div className="h1-title isLearner">"80% of enterprises will have incorporated AI by 2026"</div>
            <div className="h3-helper isLearner">Gartner</div>
          </div>
        )}
        <span className='isLearner-title'>Learn AI by Doing AI</span>
        <div className="ul-list em-row">
          <ul className='isLearner'>
            <li>
              <img src={require('../../../images/SignupImages/IconSignuptick.svg')} className="icon-signup-tick" />
              AI skills made simple – learn without coding. 
            </li>
            <li>
              <img src={require('../../../images/SignupImages/IconSignuptick.svg')} className="icon-signup-tick" />
              Stand out in your field with practical AI knowledge.
            </li>
            <li>
              <img src={require('../../../images/SignupImages/IconSignuptick.svg')} className="icon-signup-tick" />
              Flexible, hands-on learning that fits into your busy schedule.
            </li>
            <li>
              <img src={require('../../../images/SignupImages/IconSignuptick.svg')} className="icon-signup-tick" />
              Gain future-ready skills that keep you competitive.
            </li>
          </ul>
        </div>
        <div className="em-row icon-signup-logo-div">
          <img src={require('../../../images/onBoard/EwhiteLogo.png')} className="icon-signup-logo" />
        </div>
      </div>
    </div>
  )
}
